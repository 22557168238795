<template>
  <div>
    <v-card class="ma-1 ps-1">
      <v-card-title class="pa-1">
        <span>{{ $t("critical.index") }}</span>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          x-small
          fab
          @click="
            getEmployeeExcel(1);
            employee_excel = [];
          "
          class="mr-2"
          color="#6ac82d"
        >
          <v-icon>mdi-file-excel-outline</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        fixed-header
        dense
        :loading-text="$t('loadingText')"
        :no-data-text="$t('noDataText')"
        :height="screenHeight"
        :loading="loading"
        :headers="headers"
        :items="items"
        :single-expand="singleExpand"
        :options.sync="dataTableOptions"
        :expanded.sync="expanded"
        class="ma-1"
        style="border: 1px solid #aaa"
        item-key="id"
        show-expand
        :footer-props="{
          itemsPerPageOptions: [20, 50, 100, -1],
          itemsPerPageAllText: $t('itemsPerPageAllText'),
          itemsPerPageText: $t('itemsPerPageText'),
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right'
        }"
      >
      <template v-slot:item.id="{ item }">
          {{
          items
          .map(function(x) {
          return x.id;
          })
          .indexOf(item.id) + 1
          }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-3">
            <table class="infoTable ma-0 pa-0" v-if="item.reserves.length">
              <tr>
                <td class="font-weight-bold">{{ $t("#") }}</td>
                <td class="font-weight-bold">{{ $t("critical.employee") }}</td>
                <td class="font-weight-bold" style="max-width: 50px">{{ $t("actions") }}</td>
              </tr>
              <tbody>
                <tr v-for="(reserv, ind) in item.reserves" :key="ind">
                  <td>{{ ind + 1 }}</td>
                  <td>
                  <router-link
                    :to="'/users/profile/' + reserv.employee_id"
                    style="text-decoration: none"
                  >
                    <span v-if="$i18n.locale == 'uz_latin'">
                      {{ reserv.employee.firstname_uz_latin }}
                      {{ reserv.employee.lastname_uz_latin }}
                      {{ reserv.employee.middlename_uz_latin }}
                    </span>
                    <span v-else>
                      {{ reserv.employee.firstname_uz_cyril }}
                      {{ reserv.employee.lastname_uz_cyril }}
                      {{ reserv.employee.middlename_uz_cyril }}
                    </span>
                    </router-link>
                  </td>
                  <td class style="max-width: 40px">
                    <v-btn
                      v-if="$store.getters.checkPermission('critical-delete')"
                      color="red"
                      class="my-1"
                      x-small
                      text
                      @click="deleteReserveEmployeeItem(reserv)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- </v-container> -->
            <span
              v-else
              style="display: block; text-align:center; color:red;"
            >{{ $t('noDataText') }}</span>
          </td>
        </template>
        <template v-slot:item.employee="{ item }">
          <span v-if="$i18n.locale == 'uz_latin'">
            {{ item.employee.firstname_uz_latin }}
            {{ item.employee.lastname_uz_latin }}
            {{ item.employee.middlename_uz_latin }}
          </span>
          <span v-else>
            {{ item.employee.firstname_uz_cyril }}
            {{ item.employee.lastname_uz_cyril }}
            {{ item.employee.middlename_uz_cyril }}
          </span>
        </template>
        <template v-slot:item.staff="{ item }">
          <span v-if="$i18n.locale == 'uz_latin'">
            {{ item.staff.department.name_uz_latin }},
            {{ item.staff.position.name_uz_latin }}
          </span>
          <span v-else-if="$i18n.locale == 'uz_cyril'">
            {{ item.staff.department.name_uz_cyril }},
            {{ item.staff.position.name_uz_cyril }}
          </span>
          <span v-else>{{ item.staff.department.name_ru }}, {{ item.staff.position.name_ru }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="$store.getters.checkPermission('critical-update')"
            color="blue"
            small
            text
            @click="newReserveEmployeeItem(item)"
          >
            <v-icon>mdi-account-plus-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialog" @keydown.esc="dialog = false" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          <span class="headline">{{ dialogHeaderText }}</span>
          <v-spacer></v-spacer>

          <v-btn color="red" outlined x-small fab class @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="dialogForm">
            <v-row>
              <v-col cols="12">
                <label for>{{ $t("critical.employee") }}</label>
                <v-autocomplete
                  outlined
                  class="pa-0"
                  clearable
                  v-model="reserveEmployeeForm.employee_id"
                  :items="employees"
                  :rules="[v => !!v || $t('input.required')]"
                  hide-details
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="saveReserveEmployee">
            {{
            $t("save")
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="downloadExcel" hide-overlay persistent width="300">
      <v-card>
        <v-card-text class="py-1 px-3">
          <v-btn color="success" class="mx-10" @click="downloadExcel = false" text>
            <download-excel :data="employee_excel" :name="'Kritik_hodimlar_ruyxati_' + today + '.xls'">
              <span style="color: #4caf50">{{ $t("download") }}</span>
              <v-icon color="success" height="20">mdi-download</v-icon>
            </download-excel>
          </v-btn>
          <v-btn class color="error" @click="downloadExcel = false" icon>
            <v-icon color="error" height="20">mdi-close</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
const axios = require("axios").default;
import Swal from "sweetalert2";
const moment = require("moment");
export default {
  data() {
    return {
      loading: false,
      search: "",
      dialog: false,
      items: [],
      form: {},
      dialogHeaderText: "",
      page: 1,
      employees: [],
      from: 0,
      server_items_length: -1,
      dataTableOptions: { page: 1, itemsPerPage: 20 },
      expanded: [],
      singleExpand: false,
      dialogHeaderText: "",
      reserveEmployeeForm: {},
      downloadExcel: false,
      employee_excel: [],
      today: moment().format("YYYY-MM-DD"),
    };
  },
  computed: {
    screenHeight() {
      return window.innerHeight - 175;
    },
    headers() {
      return [
        {
          text: "",
          value: "data-table-expand",
          width: 30
        },
        { text: "#", value: "id", align: "center", width: 30 },
        {
          text: this.$t("critical.employee"),
          value: "employee"
        },
        { text: this.$t("critical.position"), value: "staff" },
        { text: this.$t("critical.begin_date"), value: "begin_date" },
        { text: this.$t("critical.end_date"), value: "end_date" },
        { text: this.$t("critical.description"), value: "description" },
        {
          text: this.$t("actions"),
          value: "actions",
          width: 120,
          align: "center"
        }
      ].filter(
        v =>
          v.value != "actions" ||
          this.$store.getters.checkPermission("critical-update") ||
          this.$store.getters.checkPermission("critical-delete")
      );
    }
  },
  methods: {
    updatePage($event) {
      this.getList();
    },
    updatePerPage($event) {
      this.getList();
    },
    getRef() {
      let locale = this.$i18n.locale;
      locale = locale == "uz_latin" ? "uz_latin" : "uz_cyril";
      axios
        .get(this.$store.state.backend_url + "api/staff-criticals/get-ref/" + locale)
        .then(response => {
          this.employees = response.data.map(v => ({
            value: v.id,
            text:
              v.tabel +
              " " +
              v["lastname_" + locale] +
              " " +
              (v["firstname_" + locale]
                ? v["firstname_" + locale].substr(0, 1) + ". "
                : "") +
              " " +
              (v["middlename_" + locale]
                ? v["middlename_" + locale].substr(0, 1) + ". "
                : "")
            // text: v.lastname_uz_cyril + " " + v.firstname_uz_cyril
          }));
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getList() {
      this.loading = true;
      axios
        .get(this.$store.state.backend_url + "api/staff-criticals", {
          pagination: this.dataTableOptions,
          search: this.search,
          locale: this.$i18n.locale,
        })
        .then(response => {
          this.items = response.data;
          console.log('11111', this.items);
          this.from = response.data.from;
          this.server_items_length = response.data.total;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },
    newReserveEmployeeItem(item) {
      this.dialogHeaderText = this.$t("critical.add_reserve_employee");
      this.reserveEmployeeForm = {
        id: Date.now(),
        critical_staff_id: item.id,
        employee_id: ""
      };
      this.dialog = true;
    },
    editReserveEmployeeItem(item) {
      this.dialogHeaderText = this.$t("Tahrirlash");
      this.reserveEmployeeForm = {
        id: item.id,
        critical_staff_id: item.critical_staff_id,
        employee_id: item.employee_id
      };
      this.dialog = true;
    },
    saveReserveEmployee() {
      axios
        .post(
          this.$store.state.backend_url + "api/reserves/update",
          this.reserveEmployeeForm
        )
        .then(res => {
          this.getList();
          this.dialog = false;
          const Toast = Swal.mixin({
            toast: true,
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: this.$t("create_update_operation")
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    deleteReserveEmployeeItem(item) {
      Swal.fire({
        title: this.$t("swal_title"),
        text: this.$t("swal_text"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("swal_delete")
      }).then(result => {
        if (result.value) {
          axios
            .delete(
              this.$store.state.backend_url + "api/reserves/delete/" + item.id
            )
            .then(res => {
              this.getList();
              this.dialog = false;
              Swal.fire("Deleted!", this.$t("swal_deleted"), "success");
            })
            .catch(err => {
              Swal.fire({
                icon: "error",
                title: this.$t("swal_error_title"),
                text: this.$t("swal_error_text")
                //footer: "<a href>Why do I have this issue?</a>"
              });
              console.log(err);
            });
        }
      });
    },
    getEmployeeExcel(page) {
      let new_array = [];
      this.loading = true;
      axios
        .post(this.$store.state.backend_url + "api/staff-criticals/get-excel", {
          locale: this.$i18n.locale,
          page: page,
          perPage: 1000
        })
        .then(response => {
          new_array = response.data;
          this.employee_excel = this.employee_excel.concat(new_array);
          if (response.data.length == 1000) {
            this.getEmployeeExcel(++page);
          } else {
            this.loading = false;
            this.downloadExcel = true;
          }
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getList();
    this.getRef();
  }
};
</script>
